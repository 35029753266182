import React, { FC } from 'react';
import Button from 'gatsby-theme-husky/src/common/Button/Button';
import { IRelatedProductArticle } from './model';
import './relatedProductArticle.scss';

const RelatedProductArticle: FC<IRelatedProductArticle> = ({
  title,
  description,
  cta,
  image,
  textButton,
}) => (
  <div className="article__card">
    <img className="card__img" src={image?.fallbackUrl} alt={image?.altText} />
    <h5 className="card__title">{title}</h5>
    {description ? <p>{description}</p> : null}
    <div className="related-product__button card__button--container">
      <Button classes="button--outline-dark card__button--button" link={cta[0]?.url} ariaLabel="">
        {textButton}
      </Button>
    </div>
  </div>
);

export default RelatedProductArticle;
