import React, { FC, useMemo, useState } from 'react';
import { Link } from 'gatsby';
import useScreenRecognition from 'hooks/useScreenRecognition';
import Button from 'common/Button';
import classNames from 'classnames';
import { TSecondaryNavigationProps } from './models';
import './SecondaryNavigation.scss';

const SecondaryNavigation: FC<TSecondaryNavigationProps> = ({
  categoriesList,
  currentCategory,
  categoriesListShowButtonText,
  categoriesListHideButtonText,
}) => {
  if (!categoriesList) return null;

  const { isMobile } = useScreenRecognition();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const categories = useMemo(() => {
    return categoriesList
      .filter((item) => item.properties.isCategoryShow === '1')
      .sort((a, b) => a.properties.categoryOrder - b.properties.categoryOrder);
  }, [categoriesList]);

  return (
    <>
      {categories.length ? (
        <div className="article-secondary-navigation">
          {isMobile ? (
            <Button
              className="article-button"
              btnColorVariant="outline-dark"
              handler={() => setIsOpen((prevState) => !prevState)}
            >
              {isOpen
                ? categoriesListHideButtonText || 'Hide filters'
                : categoriesListShowButtonText || 'Show filters'}
            </Button>
          ) : null}
          <div
            className={classNames('article-secondary-navigation__body', {
              'article-secondary-navigation__body--open': isMobile && isOpen,
            })}
          >
            {categories?.map((category) => (
              <div className="article-secondary-navigation__wrapper">
                {category.properties.categoryUrl[0]?.url &&
                category.properties.categoryUrl[0]?.name ? (
                  <Link
                    to={category.properties.categoryUrl[0].url[0].url}
                    className={`article-secondary-navigation__item ${
                      category.properties.categoryUrl[0].name === currentCategory[0].name
                        ? 'active'
                        : ''
                    }`}
                  >
                    {category.properties.categoryName}
                  </Link>
                ) : null}
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SecondaryNavigation;
