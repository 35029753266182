import React, { FC } from 'react';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import Breadcrumbs from 'gatsby-theme-husky/src/components/Breadcrumbs';
import Button from 'gatsby-theme-husky/src/common/Button';
import { IBannerTextSection } from './models';
import './BannerTextSection.scss';

const BannerTextSection: FC<IBannerTextSection> = ({ title, description, button, url }) => {
  const { isMobile } = useScreenRecognition();

  const isBrowser = typeof window !== 'undefined';
  let country: string | null = '';

  if (isBrowser) {
    country = localStorage.getItem('codeCountry');
  }

  return (
    <div className="banner-text">
      {url && !isMobile ? (
        <div className="banner-text__breadcrumbs">
          <Breadcrumbs url={url} light />
        </div>
      ) : null}
      <h1 className="banner-text__title">{title}</h1>
      {description ? (
        <DangerouslySetInnerHtml html={description} className="banner-text__description" />
      ) : null}
      {button?.[0]?.properties ? (
        <Button
          link={
            button[0].properties.buttonText === 'COMPRA AQUÍ'
              ? `/donde-comprar${country}`
              : (button?.[0]?.properties?.buttonLink[0].url).replace('/co/', country)
          }
          btnColorVariant="transparent-white"
          ariaLabel={button[0].properties.ariaLabel}
        >
          {button[0].properties.buttonText}
        </Button>
      ) : null}
    </div>
  );
};

export default BannerTextSection;
