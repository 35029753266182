import React, { FC, useCallback, useEffect, useMemo } from 'react';
import {
  generateLocationQueryString,
  getLocationQueryStringParam,
  getRemovedParamFromLocationQueryString,
  setUrl,
} from 'utils/browser';
import classNames from 'classnames';
import PaginationItem from './PaginationItem';
import { IPropsPagination } from './models';

import './Pagination.scss';

const PAGE_URL_PARAM = 'page';
const diffPages = 2;

const Pagination: FC<IPropsPagination> = ({
  handleActiveListPage,
  pageCount,
  activePage,
  className,
  nextContent,
  prevContent,
}) => {
  const getPaginationContent = (): Array<number | undefined> => {
    const temp: Array<number | undefined> = [0];
    for (let i = 1; i < pageCount - 1; i++) {
      if (Math.abs(i - activePage) <= diffPages) {
        temp.push(i);
      } else if (typeof temp[temp.length - 1] !== 'undefined') {
        temp.push(undefined);
      }
    }
    if (pageCount > 1) temp.push(pageCount - 1);

    return temp;
  };

  const getCurrentPageFromUrl = useMemo(
    () => () => parseInt(getLocationQueryStringParam(PAGE_URL_PARAM, 'string'), 10) || 0,
    []
  );

  const handleActiveListPageOnLoad = useCallback(() => {
    const currentPage = getCurrentPageFromUrl();

    if (!currentPage) {
      return;
    }

    handleActiveListPage(currentPage);
  }, []);

  useEffect(() => {
    handleActiveListPageOnLoad();
  }, []);

  const setPaginationUrlParams = useCallback((currentPage: number): void => {
    let url: string | null = '';
    if (currentPage) {
      url = generateLocationQueryString(PAGE_URL_PARAM, String(currentPage));
    } else {
      url = getRemovedParamFromLocationQueryString(PAGE_URL_PARAM);
    }

    setUrl(url || '');
  }, []);

  useEffect(() => {
    if (activePage !== getCurrentPageFromUrl()) {
      setPaginationUrlParams(activePage);
    }
  }, [activePage]);

  const handleChangePage = useCallback(
    (currentPage: number) => () => {
      handleActiveListPage(currentPage);
      setPaginationUrlParams(currentPage);
    },
    []
  );

  const prevPageHandler = () => {
    if (activePage > 0) handleActiveListPage((prevState) => prevState - 1);
  };
  const nextPageHandler = () => {
    if (activePage < pageCount - 1) handleActiveListPage((prevState) => prevState + 1);
  };

  return (
    <ul data-test="Pagination" className={classNames('pagination', className)}>
      {activePage > 0 ? (
        <li>
          <button
            type="button"
            aria-label="pagination-preview-page"
            className="navigate-button"
            onClick={prevPageHandler}
          >
            {prevContent}
          </button>
        </li>
      ) : null}
      {getPaginationContent().map((item) => {
        return typeof item === 'number' ? (
          <PaginationItem
            key={item}
            handlePagingClick={handleChangePage(item)}
            item={item}
            activePage={activePage}
          />
        ) : (
          <li>
            <div className="dash" />
          </li>
        );
      })}
      {activePage < pageCount - 1 ? (
        <li>
          <button
            type="button"
            aria-label="pagination-preview-page"
            className="navigate-button"
            onClick={nextPageHandler}
          >
            {nextContent}
          </button>
        </li>
      ) : null}
    </ul>
  );
};

export default Pagination;
