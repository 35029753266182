import React, { FC } from 'react';
import { Link } from 'gatsby';
import GatsbyImage from 'gatsby-theme-husky/src/common/GatsbyImage';
import Button from 'gatsby-theme-husky/src/common/Button';
import TagsList from 'gatsby-theme-husky/src/components/TagsList';
import { stringUtils, customStructurePickerUtils } from 'gatsby-theme-husky/src/utils';
import { ArticleData } from '@shared/types/common/ArticleData';
import ARTICLES from 'gatsby-theme-husky/src/shared/static/content';
import LIMITS from 'gatsby-theme-husky/src/shared/static/limits';
import { IArticleCard } from './models';
import './ArticleCard.scss';

const ArticleCard: FC<IArticleCard> = ({ article, readMoreText, ariaLabel, showTags = false }) => {
  const getArticleIntroductionTextWithoutHtml = (
    articleItem: ArticleData.ArticleContent
  ): string | null => {
    return customStructurePickerUtils.getStructurePropertiesDataWOHtml(
      articleItem.properties.articleBody,
      ARTICLES.INTRODUCTION,
      ARTICLES.INTRO_TEXT,
      80
    );
  };

  return (
    <div className="article-card" data-testid="article-card">
      <Link to={article.url} className="article-card__link">
        {article.localImage && (
          <GatsbyImage
            isLazyLoading
            wrapperClasses="article-card__image"
            fluid={article.localImage?.childImageSharp.fluid}
            alt={article.properties.articleHeroBannerMainImageAltText}
          />
        )}
      </Link>
      <h3 className="article-card__article-title">
        <Link to={article.url} className="article-card__link">
          {stringUtils.sliceText(article.properties.title, LIMITS.ARTICLE_CARD_TITLE)}
        </Link>
      </h3>
      <div className="article-card__article-text">
        {getArticleIntroductionTextWithoutHtml(article)}
      </div>
      <div className="article-card__controls">
        {showTags ? <TagsList dark tags={article.properties.tags} /> : null}

        {readMoreText && ariaLabel ? (
          <Button ariaLabel={ariaLabel} btnColorVariant="outline-dark" link={article.url}>
            {readMoreText}
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default ArticleCard;
